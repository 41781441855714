import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import imgLogo from "../images/logo-white-alpha-large.png"
import imgCoach from "../images/coach-blue-blur.jpg"
import imgSupport from "../images/support.jpg"
import "./index.css";
import Helmet from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Pricing" />
        <div>
            <div>

              <div style={{ display: 'flex', backgroundImage: `url(${imgCoach})`, backgroundColor: '#fbfbfb', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>

                <div className="blog" style={{ marginTop: 100, marginBottom: 100 }}>
                  <div className='headline-block blog-inner'>
                    <h1 className="blog-headline">Price requested</h1>
                    <p style={{ textAlign: 'center', fontSize: '1.3em', color: '#3f6375', lineHeight: '150%' }}>
                      Thank you for your request. A friendly member of our team will be in touch as soon as possible.
                    </p>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </Layout>
    );
  }
};

export default BlogPage
